import React from 'react';
import styled from 'styled-components';

import Add from '@mui/icons-material/Add';
import SmallButton from './SmallButton';

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #DDD0C8;
  color: #323232;
  padding: 1rem;
`;

const MobileNav = styled.ul`
  flex-direction: column;
  margin: 0;
  padding: 0;

  @media (max-width: 767px) {
    display: flex;
  }
`;

const Logo = styled.h1`
  font-size: 2.0rem;
  margin: 0;
`;

const NavBar = () => {
    
	const addClicked = () => {
		const link = document.createElement("a");
		link.href = '/create';
		link.click();
	}

	return (
		<Nav>
			<Logo>One Day Menu</Logo>
			<MobileNav>
				<SmallButton icon={<Add fontSize="large" />} onClick={addClicked} /> 
			</MobileNav>
		</Nav>
	);
};

export default NavBar