import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';

const Container = styled.div`
    display: 'flex';
    justifyContent: 'right';
    height: 50;
`;

const SmallButton = ({ icon, onClick }) => {
    
    const iconClicked = (event) => {
        onClick()
    };

    return (
        <Container>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={iconClicked}
                color="inherit"
            >
                {icon}
            </IconButton>
        </Container>
      );
}

export default SmallButton
