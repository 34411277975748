import React, { useRef } from 'react';
import { Box, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import CreateMenuItem from './CreateMenuItem';
import TimePicker from './TimePicker'

import { toPng, toBlob } from 'html-to-image';
import QRCode from 'react-qr-code';

import {isMobile} from 'react-device-detect';

import axios from "axios";

const generateQRCode = (url, ref) => {
    console.log(url)
    return (
        <div>
            <QRCode ref={ref} value={url} />
        </div>
    );
}

function escapeRegex(string) {
    return string.replace(/[^a-zA-Z ]/g, "");
}


const CreateMenu = ({  }) => {
    const elementRef = useRef(null);

    const [menuSubmitted, setMenuSubmitted] = React.useState(false);
    const [menuUrl, setMenuUrl] = React.useState('');
    const [menuResponse, setMenuResponse] = React.useState(null)

    const [selectedMenuLabel, setSelectedMenuLabel] = React.useState("");
    const [address, setAddress] = React.useState('');
    const [phone, setPhone] = React.useState('');

    const [openTime, setOpenTime] = React.useState('10:00 AM');
    const [closeTime, setCloseTime] = React.useState('4:00 PM');

    const [items, setItems] = React.useState([]);

    const [errorLogs, setErrorLogs] = React.useState([]);
    const [canSubmit, setCanSubmit] = React.useState(false);

    const handleLabelChange = (event) => {
        setSelectedMenuLabel(event.currentTarget.value);
        checkForErrors({label: event.currentTarget.value})
    }

    const handleAddressChange = (event) => {
        setAddress(event.currentTarget.value);
        checkForErrors({newAddress: event.currentTarget.value})
    }

    const handlePhoneChange = (event) => {
        setPhone(event.currentTarget.value);
        checkForErrors({})
    }

    const addMenuItem = () => {
        setItems([
            ...items,
            {
                id: (Math.random() + 1).toString(36).substring(7),
                name: "",
                description: "",
                price: "",
                errorLog: ""
            }
        ])

        checkForErrors({});
    }

    const removeMenuItem = (id) => {
        setItems(oldItems => {
            return oldItems.filter(item => item.id !== id)
        });
    }

    const handleItemsChange = (updatedItem) => {
        items[findIndex(updatedItem.id)] = updatedItem

        checkForErrors({});
    }

    function findIndex(id) {
        return items.findIndex(item => item.id === id);
    }

    const checkForErrors = ({ label, newAddress, open, close }) => {
        let newErrorLogs = []

        if ((!label && selectedMenuLabel.length === 0) || (label && label.length === 0)) {
            newErrorLogs.push('Need to define a label.');
        }
        if ((!newAddress && address.length === 0) || (newAddress && newAddress.length === 0)) {
            newErrorLogs.push('Need to define a address.');
        }
        if (items.length === 0) {
            newErrorLogs.push('Need to define at least one item.')
        }
        if (items.length > 0 && !noItemErrors()) {
            newErrorLogs.push('Need to address item errors.')
        }

        setErrorLogs(newErrorLogs)

        setCanSubmit(newErrorLogs.length === 0 && noItemErrors())
    }

    const noItemErrors = () => {
        return items.length > 0 && items.filter(item => item.errorLog.length > 0).length === 0
    }

    const updateOpenTime = (newTime) => {
        setOpenTime(newTime);
    }

    const updateClosetime = (closeTime) => {
        setCloseTime(closeTime);
    }

    const submitClick = (e) => {
        console.log({
            title: selectedMenuLabel,
            address: address,
            phone: phone,
            opens: openTime,
            closes: closeTime,
            items: items
        })

        const newMenu = {
            title: escapeRegex(selectedMenuLabel),
            address: escapeRegex(address),
            phone: escapeRegex(phone),
            opens: openTime,
            closes: closeTime,
            items: items
        }

        e.preventDefault();
        axios.post("https://one-day-menu-backend.onrender.com/create", newMenu).then((response) => {
            console.log(response.status, response.data);
            setMenuResponse(generateQRCode(response.data.data.url, elementRef));
            setMenuUrl(response.data.data.url);
            setMenuSubmitted(true);
        });
    }

    const shareClicked = async () => {
        const newFile = await toBlob(elementRef.current);

        const data = {
            files: [
              new File([newFile], menuUrl + '.png', {
                type: newFile.type,
              }),
            ],
            title: menuUrl,
            text: menuUrl,
        };
        
        try {
            if (!navigator.canShare(data)) {
              console.error("Can't share");
            }

            await navigator.share(data);
        } catch (err) {
            console.error(err);
        }
    }

    const downloadClicked = () => {
        toPng(elementRef.current, { cacheBust: false })
            .then((dataUrl) => {
                const link = document.createElement("a");
                link.download = menuUrl + ".png";
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    if (menuSubmitted) {
        return (
            <div className="flex flex-col justify-center items-center">
                <div className='h-16'></div>
                <div className='text-xl'>You've successfuly created a menu, it will be hosted at the following domain</div>
                <a href={menuUrl} target="_blank" rel="noopener noreferrer" className='text-sky-800 text-xl mt-4'>{menuUrl}</a>
                <div className='mt-4'></div>
                {menuResponse}
                <div className='flex justify-center mt-2'>
                    <button className='bg-sky-600 hover:bg-sky-800 rounded w-28 p-2 m-2' onClick={shareClicked}>Share</button>
                    <button className='bg-emerald-600 hover:bg-emerald-800 rounded w-28 p-2 m-2' onClick={downloadClicked}>Download</button>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='h-4'></div>
            <div className='text-rose-500'>
                {errorLogs.map(function(log) {
                    return (
                        <div key={log} className='w-full'>{log}</div>
                    )
                })}
            </div>
            <FormControl fullWidth>
                <Box mt={4} >
                    <TextField 
                        id='menu-label' 
                        value={selectedMenuLabel} 
                        label="Menu Label" 
                        variant="outlined" 
                        onChange={handleLabelChange} />
                </Box>
            </FormControl>
            <FormControl fullWidth>
                <Box mt={2} >
                    <TextField 
                        id='menu-address' 
                        value={address} 
                        label="Address" 
                        variant="outlined" 
                        onChange={handleAddressChange} />
                </Box>
            </FormControl>
            <FormControl fullWidth>
                <Box mt={2} >
                    <TextField 
                        value={phone} 
                        label="Phone" 
                        variant="outlined" 
                        onChange={handlePhoneChange} />
                </Box>
            </FormControl>
            <FormControl className='justify-center mt-2' fullWidth>
                <Box mt={4}>
                    <TimePicker label={'Opens'} time={openTime} updateTime={updateOpenTime}/>
                </Box>
            </FormControl>
            <FormControl className='justify-center' fullWidth>
                <Box mt={4}>
                    <TimePicker label={'Closes'} time={closeTime}  updateTime={updateClosetime}/>
                </Box>
            </FormControl>
            <div className={"mt-6 " + (isMobile ? 'flex flex-col' : 'flex flex-col items-center justify-center w-full')}>
                {items.map(function(item) {
                    return (
                        <CreateMenuItem key={item.id} item={item} updateItem={handleItemsChange} removeItem={removeMenuItem} />
                    )
                })}
            </div>
            <div className='flex flex-col items-center justify-center w-full mt-2'>
                <button 
                    className='w-64 bg-sky-400 hover:bg-sky-600 text-white font-bold my-1 py-2 px-2 rounded'
                    onClick={addMenuItem}>
                    Add Item
                </button>
            </div>
            <FormControl>
                <button 
                    className={
                        'w-64 text-white font-bold my-1 py-2 px-2 rounded' + 
                        (canSubmit ? ' bg-emerald-400 hover:bg-emerald-600' : ' bg-gray-400') }
                    onClick={submitClick} 
                    disabled={!canSubmit}>
                    Submit
                </button>
            </FormControl>
            <Box mt={0} >
            </Box>
        </div>
    );
}

export default CreateMenu
