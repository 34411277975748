import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';

const extractHour = (time) => {
    let splitString = time.split(':')

    return splitString[0]
}

const extractMinute = (time) => {
    let splitString = time.split(':');
    let secondSplit = splitString[1].split(' ');

    return secondSplit[0]
}

const extractAmPm = (time) => {
    let splitString = time.split(' ');

    return splitString[1];
}

const TimePicker = ({ label, time, updateTime }) => {
  const [hour, setHour] = React.useState(extractHour(time));
  const [minute, setMinute] = React.useState(extractMinute(time))
  const [ampm, setAmPm] = React.useState(extractAmPm(time))

  const updateHour = (event) => {
    setHour(event.target.value);
    updateTime(event.target.value + ":" + minute + " " + ampm)
    // console.log(openHour, event.target.value)
  };

  const updateMinute = (event) => {
    setMinute(event.target.value);
    updateTime(hour + ":" + event.target.value + " " + ampm)
  };

  const updateAmPm = (event) => {
    setAmPm(event.target.value)
    updateTime(hour + ":" + minute + " " + event.target.value)
  }

  return (
    //classes.form
    <>
        <Typography className='inline-block align-middle text-lg' >{label}</Typography>
        <span className='inline-block align-middle w-4 text-lg font-bold'></span>
        <FormControl>
            <Select
                className='w-18 h-14'
                id="open-hour-select"
                value={hour}
                onChange={updateHour}
                >
                <MenuItem value={'1'}>1</MenuItem>
                <MenuItem value={'2'}>2</MenuItem>
                <MenuItem value={'3'}>3</MenuItem>
                <MenuItem value={'4'}>4</MenuItem>
                <MenuItem value={'5'}>5</MenuItem>
                <MenuItem value={'6'}>6</MenuItem>
                <MenuItem value={'7'}>7</MenuItem>
                <MenuItem value={'8'}>8</MenuItem>
                <MenuItem value={'9'}>9</MenuItem>
                <MenuItem value={'10'}>10</MenuItem>
                <MenuItem value={'11'}>11</MenuItem>
                <MenuItem value={'12'}>12</MenuItem>
            </Select>
        </FormControl>
        <span className='inline-block align-middle w-4 text-lg font-bold mt-3'>:</span>
        <FormControl>
            <Select
                className='w-18 h-14'
                id="open-minute-select"
                value={minute}
                onChange={updateMinute}
                >
                <MenuItem value={'00'}>00</MenuItem>
                <MenuItem value={'05'}>05</MenuItem>
                <MenuItem value={'10'}>10</MenuItem>
                <MenuItem value={'15'}>15</MenuItem>
                <MenuItem value={'20'}>20</MenuItem>
                <MenuItem value={'25'}>25</MenuItem>
                <MenuItem value={'30'}>30</MenuItem>
                <MenuItem value={'35'}>35</MenuItem>
                <MenuItem value={'40'}>40</MenuItem>
                <MenuItem value={'45'}>45</MenuItem>
                <MenuItem value={'50'}>50</MenuItem>
                <MenuItem value={'55'}>55</MenuItem>
            </Select>
        </FormControl>
        <span className='inline-block align-middle w-4 text-lg font-bold'></span>
        <FormControl>
            <Select
                className='w-18 h-14'
                id="open-minute-select"
                value={ampm}
                onChange={updateAmPm}
                >
                <MenuItem value={'AM'}>AM</MenuItem>
                <MenuItem value={'PM'}>PM</MenuItem>
            </Select>
        </FormControl>
    </>
  );
}

export default TimePicker
