import React from 'react';
import { isMobile } from 'react-device-detect';

function MenuItem({ item }) {

  if (item) {

	return (
		<div className="flex mt-2">
			<div className={"flex flex-col text-left m-2" + (isMobile ? ' w-64' : ' w-96')}>
				<div className='text-slate-800 text-2xl'>
					{item.name}
				</div>
				<div className='text-slate-600 text-lg'>
					{item.description}
				</div>
			</div>
			<div className='w-20 m-2'>
				<div className='text-slate-800 text-2xl'>
					{'$' + item.price}
				</div>
			</div>
		</div>
	)
  }

  return null
};

export default MenuItem
