import React, { useState }  from 'react';
import { TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import { BrowserView, MobileView } from 'react-device-detect';

const onlyContainsNumbers = (str) => /^\d+$/.test(str);
const onlyContainsNumbersAndOneDecimal = (str) => /^\d+\.\d+$/.test(str);

function escapeRegex(string) {
    return string.replace(/[^a-zA-Z ]/g, "");
}

const CreateMenuItem = ({ item, updateItem, removeItem }) => {

    const [id] = useState(item.id);
    const [itemName, setItemName] = useState(item.name);
    const [itemDescription, setItemDescription] = useState(item.description);
    const [itemPrice, setItemPrice] = useState(item.price);
    const [errorLog, setErrorLog] = useState('');

    const handleNameChange = (event) => {
        setItemName(event.currentTarget.value);
        updateItem({ 
            id: id, 
            name: escapeRegex(event.currentTarget.value), 
            description: escapeRegex(itemDescription), 
            price: itemPrice, 
            errorLog: checkForErrors({name: event.currentTarget.value}) })
    }

    const handleDescriptionChange = (event) => {
        setItemDescription(event.currentTarget.value);
        updateItem({ 
            id: id, 
            name: escapeRegex(itemName), 
            description: escapeRegex(event.currentTarget.value), 
            price: itemPrice, 
            errorLog: checkForErrors({}) })
    }

    const handlePriceChange = (event) => {
        setItemPrice(event.currentTarget.value);
        updateItem({ 
            id: id, 
            name: escapeRegex(itemName), 
            description: escapeRegex(itemDescription), 
            price: event.currentTarget.value, 
            errorLog: checkForErrors({price: event.currentTarget.value}) })
    }

    const handleRemoval = (event) => {
        removeItem(id)
    }

    const checkForErrors = ({ name, price}) => {
        if ((!name && itemName.length === 0) || (name && name.length === 0)) {
            setErrorLog('Missing Name for Item')
            return 'Missing Name for Item';
        }
        else if ((!price && itemPrice.length === 0) || (price && price.length === 0)) {
            setErrorLog('Price is not set for Item')
            return 'Price is not set for Item'
        }
        else if ((!price && !onlyContainsNumbers(itemPrice) && !onlyContainsNumbersAndOneDecimal(itemPrice)) || 
            (price && !onlyContainsNumbers(price) && !onlyContainsNumbersAndOneDecimal(price))) {
            setErrorLog('Item Price has invalid characters.')
            return 'Item Price has invalid characters.';
        }

        setErrorLog('')
        return '';
    }

    return (
        <>
            <div className='text-rose-600'>
                {errorLog}
            </div>
            <BrowserView>
                <div className="flex border-2 border-gray-400 rounded mt-2">
                    <div className="w-96 m-2">
                        <FormControl className='w-full'>
                            <div className='w-full m-1'>
                                <TextField 
                                    id='item-name'
                                    value={itemName} 
                                    onChange={handleNameChange} 
                                    label="Name" 
                                    variant="outlined" 
                                    fullWidth />
                            </div>
                        </FormControl>
                        <FormControl className='w-full'>
                            <div className='w-full m-1'>
                                <TextField 
                                    value={itemDescription} 
                                    onChange={handleDescriptionChange}
                                    label="Description" 
                                    multiline
                                    fullWidth />
                            </div>
                        </FormControl>
                    </div>
                    <div className='flex flex-col w-32 m-2'>
                        <FormControl>
                            <div className='grow-0 m-1'>
                                <TextField 
                                    value={itemPrice} 
                                    onChange={handlePriceChange}
                                    label="Price" 
                                    fullWidth  />
                            </div>
                        </FormControl>
                        <div className='flex grow items-end justify-center m-1'>
                            <button 
                                className='bg-rose-400 hover:bg-rose-600 text-white font-bold py-2 px-7 rounded' 
                                onClick={handleRemoval}>
                                Remove
                            </button>
                        </div>
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <div className="flex border-2 border-gray-400 rounded mt-2">
                    <div className="w-96">
                        <FormControl className='w-full'>
                            <div className='w-full m-1'>
                                <TextField 
                                    value={itemName} 
                                    onChange={handleNameChange} 
                                    label="Name" 
                                    variant="outlined" 
                                    fullWidth />
                            </div>
                        </FormControl>
                        <FormControl className='w-full'>
                            <div className='w-full m-1'>
                                <TextField 
                                    value={itemDescription} 
                                    onChange={handleDescriptionChange}
                                    label="Description" 
                                    variant="outlined" 
                                    multiline
                                    fullWidth />
                            </div>
                        </FormControl>
                    </div>
                    <div className='flex flex-col w-32 ml-1'>
                        <FormControl>
                            <div className='grow-0 m-1'>
                                <TextField 
                                    id='price'
                                    value={itemPrice} 
                                    onChange={handlePriceChange}
                                    label="Price" 
                                    variant="outlined" 
                                    fullWidth  />
                            </div>
                        </FormControl>
                        <div className='flex grow items-end justify-center'>
                            <button 
                                className='bg-rose-400 hover:bg-rose-600 text-white font-bold my-1 py-2 px-2 rounded' 
                                onClick={handleRemoval}>
                                Remove
                            </button>
                        </div>
                    </div>
                </div>
            </MobileView>
        </>
    )
}

export default CreateMenuItem