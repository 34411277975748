import React from 'react';
import { Box } from '@mui/material';
import { Phone, Room, QueryBuilder } from '@mui/icons-material'
import { isMobile } from 'react-device-detect';

import RestaurantInfoText from "./RestaurantInfoText"

const RestaurantInfoCard = ({ menu }) => {

	const addressClicked = () => {
		let searchString = 'https://www.google.com/maps/place/'
		let itemsToSearch = menu.address.split(' ');

		for (let i = 0; i < itemsToSearch.length; i++) {
			if (i < itemsToSearch.length - 1) {
				searchString += itemsToSearch[i] + '+'
			}
			else {
				searchString += itemsToSearch[i]
			}
		}

		window.open(searchString);
	}

	const phoneClicked = () => {
		console.log('Phone Clicked')
		
		window.open('tel:' + menu.phone);
	}

	if (menu != null) {
		return (
			<Box mt={3} className={'' + (isMobile ? ' mx-10' : '')} >
				<div className='text-slate-800 text-5xl font-bold'>
					{menu.title}
				</div>
				<Box mt={3}/>
				<>
					<RestaurantInfoText 
						icon={<Room className='w-7 h-7' />} 
						text={menu.address} 
						onClick={addressClicked} />
					<RestaurantInfoText 
						icon={<Phone className="w-7 h-7"/>} 
						text={menu.phone} 
						onClick={phoneClicked} />
					<RestaurantInfoText 
						icon={<QueryBuilder className="w-7 h-7"/>} 
						text={menu.opening + " to " + menu.closing} 
						onClick={addressClicked}
						disabled={true} />
				</>
			</Box>
		);
	}

  return null;
}

export default RestaurantInfoCard
