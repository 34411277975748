import React from 'react';
import { Box } from '@mui/material';

import RestaurantInfoCard from "./RestaurantInfoCard"
import MenuItem from './MenuItem';

import { useLoaderData } from "react-router-dom";
import axios from "axios";

export async function loader({ params }) {

    let menu = {}

    console.log(params)

    let response = await axios.get("https://one-day-menu-backend.onrender.com/menu/" + params.menuID);
    // let response = await axios.get("http://localhost:8000/menu/" + params.menuID);

    console.log(response.status, response.data.row);

    let item1 = response.data.row[0]

    menu = {
        title: item1.label,
        address: item1.address,
        phone: item1.phone,
        opening: item1.open,
        closing: item1.close,
        items: response.data.row
    }

    console.log(menu);

    return menu
}

const Menu = () => {

    const menu = useLoaderData();

    if (menu) {
        return (
            <>
                <div className="flex justify-center items-center">
                    <RestaurantInfoCard menu={menu} />
                </div>
                <Box mt={2} />
                <div className="flex flex-col justify-center items-center">
                    {menu.items.map(function(item) {
                        return (
                            <MenuItem key={item.id} item={item} />
                        )
                    })}
                </div>
            </>
        )
    }

    return null
}

export default Menu
