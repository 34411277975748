import Home from './containers/Home'
import CreateMenu from './containers/Menu/CreateMenu';
import Menu, {
	loader as contactLoader,
} from './containers/Menu/Menu';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './App.css';

function App() {

	const router = createBrowserRouter([
		{
			path: "/",
			element: <Home />,
			errorElement: <Home />
		},
		{
			path: "menu/:menuID",
			element: <Menu />,
			loader: contactLoader,
			errorElement: <Home />
		},
		{
			path: "create",
			element: <CreateMenu />,
			errorElement: <Home />
		},
	]);	

	return (
		<div className="App">
			<RouterProvider router={router} />
		</div>
	);
}

export default App;
