import React from 'react';

import { Typography } from '@mui/material';


const RestaurantInfoText = ({ icon, text, disabled, onClick }) => {

    return (
        <button 
        className="bg-transparent text-slate-800 text-lg py-2 rounded flex items-center h-10" 
        onClick={onClick} 
        disabled={disabled} >
            {icon}
            <Typography className='pl-5' variant="subtitle1" color="textSecondary">
                {text}
            </Typography>
        </button>
    )
}

export default RestaurantInfoText