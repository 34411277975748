import React from 'react';
import styled from 'styled-components';

import NavBar from './NavBar/NavBar';

const CreateButton = styled.button`
  background-color: #323232;
  color: #DDD0C8;
  &:hover {
    background-color: #1e1e1e;
  }
`;

const Home = () => {

    const createClicked = () => {
        const link = document.createElement("a");
		link.href = '/create';
		link.click();
    }

    const visitClicked = () => {
        window.open('https://www.onedaymenu.ca/menu/3bbc94cd03fb21323649c9c6d989fa79');
    }

    return (
        <>
            <NavBar />
            <div className='flex flex-col justify-center items-center'>
                <div className='mt-6 text-2xl'>
                    Create a menu and host it today.
                </div>
                <CreateButton onClick={createClicked}
                    className='mt-8 rounded p-4 text-xl font-bold w-64'>Create Menu
                </CreateButton>
                <div className='mt-8 text-2xl'>
                    Check out a sample below
                </div>
                <img className='mt-8' src='./sample.png' />
                <CreateButton onClick={visitClicked}
                    className='mt-8 rounded p-4 text-xl font-bold w-64'>
                    Visit
                </CreateButton>
            </div>
        </>
    );
}

export default Home;
